.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    width: 100%;
    box-sizing: border-box;
    /* background-color: rgb(253, 253, 253); */
    background-color: #1b1a1a;
    position: relative;
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    box-sizing: border-box;
}

.divider-horizontal {
    position: absolute;
    /* background-color: rgb(62, 61, 61);  */
    background-color: rgb(241, 241, 180);
    height: 2px;
    width: 100%;
    transform: translateY(-50%);
}

.divider-vertical {
    position: absolute;
    /* background-color: rgb(62, 61, 61);  */
    background-color: rgb(241, 241, 180);
    width: 2px;
    height: 100%;
    transform: translateX(-50%);
}

.grid-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.image-content {
    max-width: 90%; 
    max-height: 80%;
    border-radius: 5px;
    object-fit: cover;
}

.grid-text {
    margin-top: 10px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-align: left;
}

.image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.134), rgba(0, 0, 0, 0.5));
    opacity: 0;
    transition: opacity 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.image-overlay:hover {
    opacity: 1;
    border: 1px solid black;
}

.image-overlay:hover > .image-content {
    transform: scale(1.2);
}

.overlay-content {
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    padding: 20px; /* Add padding to the overlay content for better spacing */
}

.image-overlay:hover .overlay-content {
    display: block;
}

.technologies {
    margin-top: 10px;
    margin-bottom: 40px;
    position: relative;
    top: 10%;
}

.overlay-content .technologies img {
    width: 2.8vw;
    height: auto;
    margin: 10px;
}

.github-link {
    position: relative;
    top: 8%;
    font-size: 5.5vw !important;
    text-decoration: none;
    color: white;
}

.github-link:hover {
    opacity: 75%;
}
@media screen and (max-width: 1199px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr); 
    }

    .overlay-content .technologies img {
        min-width: 35px;
    }

    .github-link {
        font-size: 10vw !important;
    }
}

@media screen and (max-width: 767px) {
    .grid-container {
        grid-template-columns: 1fr;
    }

    .overlay-content .technologies img {
        min-width: 6vw;
        margin: 2vw;
    }

    .github-link {
        font-size: 18vw !important;
    }

    .divider-horizontal,
    .divider-vertical {
        display: none;
    }

    .grid-item:not(:last-child)::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: rgb(62, 61, 61);
        bottom: 0;
        left: 0;
    }
}

