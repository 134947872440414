.navButton::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(241, 241, 180);
    transform-origin: center;
    transition: transform 0.4s ease-out;
}

.navButton:hover::after {
    transform: scaleX(0.6);
    transform-origin: center;
}

.burgerButton:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 6%;
    left: 12%;
    background-color: black;
    transform-origin: left;
    transition: transform 0.4s ease-out;
}

.burgerButton:hover::after {
    transform: scaleX(0.76);
    transform-origin: left;
}

.nav-logo {
    width: auto;
    height: 2vw;
}

.burgerNavItem {
    text-decoration: none;
}

.limited-nav-container {
    display: block;
}

.extended-nav-container {
    display: flex;
}

.nav-social {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-social:hover > button {
    color: rgb(241, 241, 180);
    animation: hop 1.5s;
}

#burger-icon:hover {
    color: rgb(241, 241, 180);
    animation: hop 1.5s;
}

@keyframes hop {
    0% { transform: translate(0px, 1.5px) }
    20% { transform: translate(0px, -1.5px) }
    40% { transform: translate(0px, 1.5px) }
    60% { transform: translate(0px, -1.5px) }
    80% { transform: translate(0px, 1.5px) }
    100% { transform: translate(0px, 0px) }
}

@media screen and (max-width: 1535px) {
  .nav-logo {
    height: 22px;
  }
}