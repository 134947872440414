.hero-container {
  height: 65vw;
  width: 100%;
  position: relative;
}

.bg-item {
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -2;
}

.background-mask {
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 60%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}

.trails-text {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  will-change: transform, opacity;
}

.text1 {
  font-weight: 300;
  letter-spacing: 2vw;
  font-size: 8.5vw;
  font-family: 'Noto Sans TC Variable', sans-serif;
}

.text2 {
  font-weight: 300;
  letter-spacing: 1.5vw;
  font-size: 8.5vw;
  font-family: 'Noto Sans TC Variable', sans-serif;

}

.text3 {
  font-weight: 300;
  letter-spacing: 0.2em;
  font-size: 2.5vw;
  color: rgb(241, 241, 180);
  font-family: 'Noto Sans TC Variable', sans-serif;
}

@media screen and (max-width: 1199px) {
  .hero-container {
    height: 80vw;
  }
}

@media screen and (max-width: 767px) {
  .hero-container {
    height: 90vw;
  }
}