.experience-container {
    position: relative;
    padding: 2px;
    background-color: #1b1a1a;
}

.carousel-slide {
    height: 100%;
    background-color: rgb(253, 253, 253);
    padding: 20px 20px 20px 20px; 
}

.job-technologies {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
}

.job-technologies img {
    width: 2.8vw;
    height: auto;
    margin: 1vw 1vw 1vw 1vw;
}

.carousel-icon {
    width: 5vw;
}

.list-container {
    display: grid;
    justify-content: center;
    list-style-position: inside;
}

.list {
    display: flex;
    flex-direction: column;
}

.list-item {
    color: white;
    padding-left: 5px;
}

.list-item-content {
    position: relative;
    left: 5px;
}

.list-item::marker {
    content: '> ';
    font-size: 2vw;
    font-weight: 1000;
}

.slide-image-skeleton {
    position: relative;
    width: 100%;
    padding-top: 66.67%;
    opacity: 0%;
}

.swiper-container-background {
    position: absolute;
    background-size: cover;
    height: 100%;
    width: 100%;
    transition: background 200ms ease-in 300ms;
    filter: blur(7px);
}

swiper-container {
    padding-top: 2vw;
    padding-bottom: 1vw;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

swiper-wrapper {
    align-items: center;
}


swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 15px;
    margin-top: 50px;
    border-radius: 15px;
}

.slide-base {
    width: 100%;
    height: 100%;
    position: relative;
    perspective: 1000px;
  }

.slide-base-content {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    cursor: pointer;
}

.slide-image-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.37);
    z-index: -1;
}

.slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    padding: 5px;
}

.swiper-navigation-container {
    background-color: #0f0e0e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5vw;
}

.nav-button {
    height: 3vw;
    width: 4vw;
}

@media screen and (max-width: 1199px) {
    .job-technologies img  {
        min-width: 35px;
        margin: 10px 15px 10px 15px;
    }

    .carousel-icon {
        width: auto;
        height: 5vw;
    }

    .list-item {
        margin-top: 6px;
    }
}

@media screen and (max-width: 767px) {
    .job-technologies img  {
        min-width: 4vw;
        margin: 1vw 1vw 1vw 1vw;
    }

    .carousel-icon {
        width: auto;
        height: 5vw;
    }

    .list-item {
        padding-left: 2vw;
        margin-top: 0.1vw;
    }
    
    .list-item-content {
        left: 3vw;
    }

    .nav-button {
        height: 2vw;
        width: 3vw;
        min-height: 15px;
    }
}
