

.contact-footer {
    background-color: #0f0e0e;
    height: 25vw;
    width: 100%;
}

.footer-content {
    width: 100%;
    height: 100%;
}

a.social-wrapper:hover {
    opacity: 90%;
    animation: hopv2 1.5s;
    transition: ease-in;
}

.social-wrapper:hover > .email {
    color: #fc9393;
}

.social-wrapper:hover > .github {
    color: #8c71b4;
}

.social-wrapper:hover > .linkedin {
    color: #0685c9;
}

.social-contact-icon {
    color: rgb(253, 253, 253);
}

.social-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
    font-size: 5.5vw;
    color: rgb(253, 253, 253);;
}

.footer-divider {
    width: 2px;
    height: 5.5vw;
    background-color: hsla(0, 0%, 96.5%, 0.1);
    transform: rotate(25deg);
}

@media screen and (max-width: 1199px) {
    .social-wrapper {
        font-size: 70px;
    }

    .footer-divider {
        min-height: 70px;
    }
}

@media screen and (max-width: 767px) {
    .social-wrapper {
        font-size: 10vw;
    }

    .footer-divider {
        min-height: 12vw;
    }
}

@keyframes hopv2 {
    0% { transform: translate(0px, -0.15vw) }
    20% { transform: translate(0px, 0.15vw) }
    40% { transform: translate(0px, -0.15vw) }
    60% { transform: translate(0px, 0.15vw) }
    80% { transform: translate(0px, -0.15vw) }
    100% { transform: translate(0px, 0vw) }
}