* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}

::-webkit-scrollbar {
  display: none;
}

body {
  scrollbar-width: none;
}

.page-content {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.content {
  scroll-behavior: smooth;
}

.separator {
  height: 50px;
  width: 100vw;
  background-color: black;
}

.separator-light {
  height: 100px;
  width: 100vw;
  background-color: white;
}
